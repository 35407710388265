import { useDataStore } from "@/stores/data.js";
const store = useDataStore();
const { query, mutate } = store;
const service = "BOOKKEEPING";
import { useToast } from "vue-toastification";
const toast = useToast();

export const GETRECEIPTS = async (
  pagination,
  archived = null,
  status = null
) => {
  // getBookkeepingClients
  const payload = {
    paging: {
      limit: 10,
      page: 1,
      search: null,
      sort: null,
      filter: null,
      ...pagination,
    },
    status,
    archived,
  };
  try {
    const res = await query({
      endpoint: "ListBookKeepingReceipt",
      service,
      payload,
    });

    if (res.success) {
    console.log("receipts here", res.data);
    store.$patch({
      bookkeepingReceipts: res.data,
    });
    }else{
    toast.error(res.message);

    }

    console.log(res);
  } catch (error) {
    throw error;
  }
};

export const DOWNLOAD_ALL_RECEIPTS = async (pagination) => {
  const payload = {
    input: {
      limit: 999,
      page: 1,
      search: null,
      sort: null,
      filter: null,
      ...pagination,
    },
  };
  try {
    let res = await query({
      endpoint: "DownloadAllReceipts",
      payload,
      service,
    });
    return res;
  } catch (e) {
    toast.error(e.message);
  }
};
export const GETARECEIPTDETAIL = async (id,companyId) => {
  // getBookkeepingClients
  const payload = {
    viewBookKeepingReceiptId: id,
    // "companyId": companyId

  };
  try {
    const res = await query({
      endpoint: "ViewBookKeepingReceipt",
      service,
      payload,
    });

    if (res.success) {
      return res.data;
    } else {
      toast.error(res.message);

      return null;
    }
  } catch (error) {
    throw error;
  }
};
