<template>
  <div>
    <div class="flex items-center justify-between mb-5">
      <easiBackButton />
    </div>
    <easiLoading v-if="isLoading" />
    <PreviewInvoice
      detailPage
      v-else
      showPay
      :formDetails="receipt ? details.receipt : details.invoice"
      :bankDetails="details.companyBankDetails"
      :receipt="receipt"
    />
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import PreviewInvoice from "@/components/BookKeeping/Invoice/PreviewInvoice.vue";
import { useRoute, useRouter } from "vue-router";
import { GETANINVOICEDETAILED } from "@/services/book-keeping/invoice";
import { GETARECEIPTDETAIL } from "@/services/book-keeping/receipts";
import { useToast } from "vue-toastification";

const props = defineProps({
  receipt: Boolean,
});
const route = useRoute();
const router = useRouter();
const toast = useToast();

const invoiceId = computed(() => route.params.id);
const companyId = computed(() => route.query.companyId);

const isLoading = ref(false);
const details = ref({});
onMounted(async () => {
  isLoading.value = true;
  try {
    const res = props.receipt
      ? await GETARECEIPTDETAIL(invoiceId.value)
      : await GETANINVOICEDETAILED(invoiceId.value, companyId.value);
    if (res) {
      console.log('tredfgh',res)
      details.value = res;
      isLoading.value = false;
    } else {
      toast.error(`${props.receipt ? "Receipt" : "Invoice"} not found`);
      // router.push("/");
    }
  } catch (e) {
    toast.error(`${props.receipt ? "Receipt" : "Invoice"} not found`);
    if (props.receipt) {
      router.go(-1);
    } else {
      router.push("/");
    }
  }
});
</script>

<style lang="scss" scoped></style>
